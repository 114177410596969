// Vendors
import $ from "jquery";
import Vue from 'vue';
import slick from 'vue-slick';
import VueScrollTo from "vue-scrollto";
import VueCollapse from 'vue2-collapse';
import * as VueGoogleMaps from 'vue2-google-maps';

class App {

    constructor() {
        window.jQuery = $;
        window.$ = $;

        Vue.use(VueScrollTo, {
            container: "body",
            duration: 500,
            easing: "ease",
            offset: 0,
            force: true,
            cancelable: true,
            onStart: function(element) {},
            onDone: function (element) {},
            onCancel: function (element) {},
            x: false,
            y: true
        });

        Vue.use(VueCollapse);

        Vue.use(VueGoogleMaps, {
            load: {
                key: 'AIzaSyAAZo1INSC1Ho6jYcg5AcLQKDG24Z_0ByY',
                language: 'nl-BE',
                region: 'be',
            }
        });
    }

    init() {

        var FormBuilderSubmitted = document.getElementById("FormBuilderSubmitted");
        if (FormBuilderSubmitted) {
          window.location.hash = '#contact';
        }

        new Vue({
            el: '#app',
            delimiters: ['<%', '%>'],
            components: {
                // Vendors
                'slick': slick,
                'google-map': VueGoogleMaps.Map,
                'gmap-marker' : VueGoogleMaps.Marker
            },

            data() {
                return {
                    selectedEvent: null,
                    currentSlide: 0,
                };
            },

            mounted() {
                const jenzenSlick = this.$refs['jenzen-slick'];
                if (jenzenSlick) {
                    jenzenSlick.$on('beforeChange', this.handleBeforeChange);
                }
            },

            methods: {
                select(id) {
                  this.selectedEvent = id;
                },
                slickGoTo(index) {
                  this.$refs["jenzen-slick"].$el.slick.slickGoTo(parseInt(index));
                },
                handleBeforeChange(event, slick, currentSlide, nextSlide) {
                  this.currentSlide = nextSlide;
                },
            }
        });
    }
}

$(() => {

    const app = new App();

    app.init();

});